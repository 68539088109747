header
	padding 8px 0 15px
	color #999796
	background #110d0e
	border-bottom 1px solid #2b2828
	.block-logo
		float left
		overflow hidden
		img
			display block
			float left
			margin 0 35px 0 8px
		.describe
			float left
			font-size 14px
			padding-top 14px
			width 200px
	.block-contacts
		float right
		padding-top 8px
		.contacts
			float right
			padding-left 35px
			width 190px
			font-size 12px
		.phone
			margin-bottom 8px
			font 22px 'FedraSerifPro A Book'
			color #fff
	.open_modal
		float left
		padding 7px 0 2px
		font-size 12px
		border-bottom 1px solid #999796
	.location
		float left
		padding 14px 0 0 30px
		font-size 14px
.main
	padding 50px 0 80px
	height 494px
	background #120c10 url("./img/bg_main.jpg") no-repeat 50% 100%
	h1
		font 45px/1.15 'FedraSerifPro A Normal'
	.describe
		margin-top 3px
		color #717171
		font 17px 'FedraSerifPro A BookItalic'
	.guaranty
		display inline-block
		padding 0 35px 2px 5px
		margin-top 8px
		font 17px 'FedraSerifPro A BookItalic'
		background #5d5d5d
	.director
		position absolute
		right 0
		bottom 0
		font-size 14px
		.name
			margin-bottom 5px
			font 24px 'FedraSerifPro A BookItalic'
	.block-form
		background #231e23
		margin-top 30px
		padding 30px 45px
		width 405px
		h2
			margin-bottom 17px
			color #9a9a9a
			font-size 18px
		form
			overflow hidden
			&>div
				float left
				margin-bottom 12px
				&:nth-child(even)
					margin-left 12px
.activity
	padding 75px 0 82px
	h3
		margin-bottom 22px
		text-transform uppercase
		font 18px 'FedraSerifPro A Book'
	.open_modal
		margin-top 43px
		width 175px
		height 30px
		font-size 12px
		border 1px solid currentColor
		text-align center
		line-height 30px
		border-radius 15px
		&:hover
			background #ececec
			color #140f13
	.h2
		text-align center
		margin-top 35px
		padding 35px 0 0 0
		&.h-line:after
			top 0
			left 50%
			margin-left -13px
.list-steps
	margin-top 60px
	position relative
	background url("./img/arrs.png") no-repeat 35% 10px
	.spec-txt
		color #a3a3a3
		font-family 'ProximaNova-Bold'
		line-height 1.4
		&.reg
			margin-top 35px
	.steps-describe
		font-size 17px
		margin-bottom 30px
	>li
		width 370px
		&.steps-steps1
			height 460px
			margin-bottom 150px
		&.steps-steps2
			position absolute
			right 0
			top 105px
			width 470px
		li
			margin-bottom 19px
			color #a3a3a3
			font-family 'ProximaNova-Light'
.what-do
	padding 79px 0 113px
	background url("./img/bg_do.jpg") no-repeat 50% 100%
	background-size cover
	.content
		width 1000px
	h2
		text-align center
	.list-aim
		margin 35px 0
		li
			margin 0 3px;
			padding 30px 10px 35px 74px
			background url("./img/aim.png") no-repeat 12px 50%
			height 70px
			width 236px
			line-height 1.7
			border 1px solid #7f7473
			&:nth-child(2)
				padding-top 40px
				padding-bottom 25px
	.describe
		margin-bottom 50px
		text-align center
	.list-do
		position relative
		width 100%
		height 430px
		color #fff
		line-height 1.8
		background url("./img/angle.png") no-repeat 0 20px
		h3
			margin-bottom 25px
			font 24px 'FedraSerifPro A Normal'
		li
			position absolute
			width 320px
			&.do-do1
				width 332px
				top 3px
				right 75px
			&.do-do2
				top 204px
				left 2px
				text-align right
			&.do-do3
				bottom 30px
				right 0px
				width 270px
.steps-work
	padding 72px 0
	background #fff
	color #1c181c
	.content
		width 1000px
	h2
		padding-bottom 27px
	.digits-steps
		display block
		margin 40px 0 35px

	.list-steps-action
		ul
			font-family: 'ProximaNova-Light'
		h3
			color #9a7700
			font 24px 'ProximaNova-Bold'
		b
			font-family 'ProximaNova-Extrabold'
		&>li
			width 230px
			li
				margin-top 20px
			&+li
				margin-left 40px
			&.steps-stp1
				margin-left 10px
			&.steps-stp3
				width 190px
			&.steps-stp4
				width 220px
				li
					margin 0
.guaranty
	padding 70px 0 40px
	background url("./img/bg_guar.jpg") no-repeat 50% 0
	background-size cover
	color #fff
	.list-works
		margin 25px 0 35px
		padding-bottom 73px
		width 405px
		background url("./img/rope.png") no-repeat 50% 100%
		h3
			font bold 30px "garamond"
			text-transform uppercase
			span
				font-size 100px
				text-transform none
		.wk-work1
			width 200px
		.wk-work2
			float right
			width 140px
	.list-learns
		margin-top 40px
		width 320px
		li
			overflow hidden
			margin-bottom 37px

		.digit
			float left
			margin-right 20px
			width 36px
			height 37px
			background url("./img/elipse.png") no-repeat 0 0
			font 10px/37px 'ProximaNova-Bold'
			text-align center
		.block-txt
			width 260px
			float right
			line-height 1.9
		h3
			margin-bottom 15px
			font 36px/0.8 'FedraSerifPro A Normal'
		span
			text-decoration underline
.history
	padding 85px 0 0
	.content
		width 1000px
		border-bottom 1px solid #4f4c4f
		height 715px
	h2
		margin-bottom 70px
	.num-dates
		li
			width 120px
			font 18px "garamond"
			height 25px
			position relative
			cursor pointer
			&.active
				color: #8e7427;
			&:nth-child(1)
				margin 0 10px 0 5px
			&:nth-child(3)
				margin 0 20px 0 18px
			&:nth-child(5)
				margin 0 20px 0 18px
			&:nth-child(7)
				width 115px
				margin 0 10px 0 25px
			&:last-child
				width 36px
	.list-dates
		width 1000px
		margin-bottom 34px;
		background url("./img/bg_scale.png") no-repeat 50% 50%;
		&>li:before
			content:""
			position absolute
			top 0
			left 0
			height 35px
			opacity 0
			-webkit-transition: opacity 1.5s ease;transition: opacity 1.5s ease;
		&>li:after
			content:""
			position absolute
			opacity 0
			width 29px
			height 35px
			background url("./img/angle-his.png") no-repeat 0 0
			bottom -44px
			left 50%
			margin-left -14px
		&>li
			width 125px
			height 35px
			position relative
			cursor pointer;
			&.ld-dat1
				width 448px
			&.ld-dat2
				width 280px
				margin-right 103px
			&.ld-dat3
				width 133px
			&.ld-dat4
				width 36px
			&.active
				&.ld-dat4
					opacity 1
					background url("./img/scale-4.png") no-repeat 0 100%;
				&.ld-dat1:before
					opacity 1
					width 448px
					background url("./img/scale-1.png") no-repeat 1px 100%;
				&.ld-dat2:before
					opacity 1
					width 315px
					left -35px
					background url("./img/scale-2.png") no-repeat 0 100%;
				&.ld-dat3:before
					opacity 1
					width 142px
					background url("./img/scale-3.png") no-repeat 0 100%;
				&.visibleangle:after
					opacity 1
				&.ld-dat2:after
					left 107px
					margin 0

.thanks
	padding 85px 0
	h2
		margin-bottom 35px
	.block-slider
		height 400px
.questions
	padding 80px 0 135px
	background #020102 url("./img/bg_footer.jpg") no-repeat 50% 100%
	text-align center
	.describe
		margin-top 12px
		color #ccc
	.block-form
		width 370px
		margin 40px auto 45px auto

.footer
	height 52px
	line-height 52px
	vertical-align middle
	a,span
		display inline-block
		vertical-align middle
		color #b3a4a7
		font 14px 'ProximaNova-Light'
	img
		display block
		margin 0 4px 0 8px



